import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Fade, Slide } from 'react-slideshow-image'
import styled from '@emotion/styled'
import 'react-slideshow-image/dist/styles.css'

import Seo from '~/components/seo'
import Navigation from '~/components/Navigation'
import ProductForm from '~/components/ProductForm'
import Footer from '~/components/Footer'
import {
  Container,
  TwoColumnGrid,
  MainContent,
  breakpoints,
} from '~/utils/styles'
import '../../../static/horizon-styles.css'
import StoffHintergrund from '../../images/stoff-hintergrund.mp4'

export const Title = styled.h1`
  color: white;
  font-size: 4rem;
  font-family: 'Old Standard TT', serif;
  font-weight: 400;
  margin: 0;
  padding: 0;

  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
  }
`

const BackgroundVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 3vw);
  grid-gap: 1rem;

  @media (max-width: ${breakpoints.l}px) {
    display: block;
    margin-top: 5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    & > * {
      margin-bottom: 2.5rem;
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

const GridItemOne = styled.div`
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 10;
  overflow: hidden;
`

const GridItemTwo = styled.div`
  grid-column-start: 3;
  grid-column-end: 6;
  grid-row-start: 8;
  grid-row-end: 12;
  overflow: hidden;
`

const GridItemThree = styled.div`
  grid-column-start: 10;
  grid-column-end: 12;
  grid-row-start: 3;
  grid-row-end: 10;
  overflow: hidden;
`

const GridItemFour = styled.div`
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 7;
  grid-row-end: 10;
  z-index: 2;
`

const ProductTitle = styled.h1`
  font-size: 2.25rem;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-weight: 400;
  margin: 0 0 0.5rem;
  line-height: 1.4;
  text-align: center;
`

const ProductDescription = styled.div`
  margin-top: 40px;
  font-weight: 300;

  a {
    color: inherit;
  }
`

const Fader = ({ images }) => {
  const properties = {
    duration: 4000,
    transitionDuration: 350,
    infinite: true,
    indicators: false,
    arrows: false,
    autoPlay: false,
  }

  return (
    <Fade {...properties}>
      {images.map((image, i) => (
        <div className="each-fade" key={i}>
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" />
        </div>
      ))}
    </Fade>
  )
}

const NurEinHerzPage = ({ data }) => {
  const product = data.shopifyProduct
  const {
    nureinherz_shooting_1,
    nureinherz_shooting_2,
    nureinherz_shooting_3,
    nureinherz_shooting_4,
    nureinherz_shooting_5,
    nureinherz_shooting_6,
    nureinherz_shooting_7,
  } = data

  return (
    <div style={{ color: 'black' }}>
      <Seo title={product.title} description={product.description} />
      <Navigation color={'black'} />
      <BackgroundVideo autoPlay playsInline muted loop>
        <source src={StoffHintergrund} type="video/mp4" />
      </BackgroundVideo>
      <Grid>
        <GridItemOne>
          <Fader images={[nureinherz_shooting_3, nureinherz_shooting_4]} />
        </GridItemOne>

        <GridItemTwo>
          <Fader
            images={[
              nureinherz_shooting_2,
              nureinherz_shooting_5,
              nureinherz_shooting_7,
            ]}
          />
        </GridItemTwo>

        <GridItemThree>
          <Fader images={[nureinherz_shooting_1, nureinherz_shooting_6]} />
        </GridItemThree>

        <GridItemFour>
          <Title>
            <span style={{ color: 'black' }}>NUR EIN</span> HERZ
          </Title>
        </GridItemFour>
      </Grid>
      <Container>
        <MainContent>
          <TwoColumnGrid>
            <GatsbyImage
              image={
                product.images[0].localFile.childImageSharp.gatsbyImageData
              }
              alt="Produktfoto NurEinHerz"
            />
            <div>
              <ProductTitle>{product.title}</ProductTitle>
              <ProductForm product={product} dark={false} color="black" />
              <ProductDescription
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
            </div>
          </TwoColumnGrid>
        </MainContent>
        <Footer color="black" />
      </Container>
    </div>
  )
}

export const query = graphql`
  query ($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId: storefrontId
        selectedOptions {
          name
          value
        }
      }
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 910
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
          }
        }
      }
    }
    nureinherz_shooting_1: file(relativePath: { eq: "NurEinHerz_C_1.jpg" }) {
      childImageSharp {
        original {
          src
        }
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    nureinherz_shooting_2: file(relativePath: { eq: "NurEinHerz_C_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    nureinherz_shooting_3: file(relativePath: { eq: "NurEinHerz_C_3.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    nureinherz_shooting_4: file(relativePath: { eq: "NurEinHerz_C_4.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    nureinherz_shooting_5: file(relativePath: { eq: "NurEinHerz_C_5.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    nureinherz_shooting_6: file(relativePath: { eq: "NurEinHerz_C_6.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    nureinherz_shooting_7: file(relativePath: { eq: "NurEinHerz_C_7.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`

export default NurEinHerzPage
